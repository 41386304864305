<script lang='ts'>
  import {onMount} from 'svelte'
  import {navigate} from 'svelte-routing'

  import {getIdFromIso} from '@/helpers/apiCall'
  import {fetchData} from '@/helpers/fetchHelpers'
  import {_} from '@/helpers/i18n'

  import LearnScriptWidget from '@/components/advanced/LearnScriptWidget.svelte'
  import ScriptBatchDetails from '@/components/advanced/ScriptBatchDetails.svelte'
  import Flag from '@/components/forum/Flag.svelte'
  import IconArrowRight from "@/components/icons/phosphor/IconArrowRight.svelte";
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  export let id: number
  export let iso = ''

  let script: any = {}
  let translatedScript: any = {}
  let original: any[] = []
  let translated: any[] = []
  let history: any[] = []
  let learnMode = false
  let language: number
  let stats: any

  const loadScript = async () => {
    const data = await fetchData('main/scriptsLearnLoad', { id, language })
    script = data.script || {}
    translatedScript = data.translatedScript || {}
    original = data.original
    translated = data.translated
    history = data.history
  }

  onMount(() => {
    language = getIdFromIso(iso)
    loadScript()
  })

  const practiceScript = async () => {
    navigate(`/${iso}/advanced/practiceScript/${id}`)
    // eslint-disable-next-line no-console
    console.log({ original, translated, translatedScript })
  }

  const batchDetails = async (batchId: number) => {
    stats = await fetchData('main/scriptsBatchDetails', {batchId, id})
  }
</script>

<Breadcrumb
  breads={[
  {text:'',url:`/${iso}`},
  {text:'Language scripts',url:`/${iso}/advanced/language-scripts`},
  {text:`${script.title || 'unknown script'}` }
  ]}
/>

<div class='flags-wrapper'>
  {#key translatedScript.id_language}
    <Flag id={translatedScript.id_language} />
  {/key}
  <IconArrowRight weight='regular' />
  {#key iso}
    <Flag {iso} />
  {/key}
</div>
<div class='buttons-wrapper'>
  <button type='button' on:click={() => {learnMode = true}}>Learn</button>
  <button type='button' on:click={practiceScript}>Practice</button>
</div>
{#if learnMode}
  <LearnScriptWidget {original} {translated} />
{/if}

<table>
  {#each Object.values(history).reverse() as batches}
    <tr>
      <th>{$_('script.review')}: {batches[0].id_review} • {batches[0].time_start}</th>
      <th>{$_('script.total')}</th>
      <th>{$_('mix.correct')}</th>
      <th>{$_('mix.wrong')}</th>
      <th>{$_('script.time')}</th>
    </tr>
    {#each batches as batch}
      {#if batch.status === 'complete'}
        <tr>
          <td>
            <button class='button-small' type='button' on:click={() => batchDetails(batch.id)} on:keypress={() => {}}>{$_('mix.seeDetails')}</button>
          </td>
          <td>{batch.correct + batch.wrong}</td>
          <td>{batch.correct}</td>
          <td>{batch.wrong}</td>
          <td>{batch.time}</td>
        </tr>
      {:else}
        <tr>
          <td>{`${batch.active_lines}`?.split(',').length}</td>
          <td colspan='3'>{$_('script.inProgress')}</td>
      {/if}
    {/each}
  {/each}
</table>
{#if Object.keys(stats?.results || {}).length}
  <Overlay onClick={() => {stats = 0}}>
    <ScriptBatchDetails details={stats} />
  </Overlay>
{/if}
<style lang='scss'>
  .flags-wrapper, .buttons-wrapper {
    display: flex;
    gap: 1.2rem;
  }

  .button-small {
    padding: 0.4rem 0.8rem;
    font: var(--Regular-300);
  }
</style>
