<script lang='ts'>
  import { clickOutside } from '@/helpers/mixHelpers'

  import IconDotsThree from "@/components/icons/phosphor/IconDotsThree.svelte";
  import IconDotsThreeVertical from "@/components/icons/phosphor/IconDotsThreeVertical.svelte";
  import FakeButton from '@/components/ui/FakeButton.svelte'

  export let dotsVariant: 'vertical' | 'horizontal' = 'vertical',
    sideView: 'left' | 'right' = 'left'

  let showMenu = false

  const toggleMenu = () => {
    showMenu = !showMenu
  }

  const closeMenu = () => {
    showMenu = false
  }

  const handleSlotClick = () => {
    closeMenu()
  }
</script>

<div
  class='kebab-wrapper'
  role='button'
  tabindex='0'
  on:click_outside={closeMenu}
  use:clickOutside
>
  <FakeButton onClick={toggleMenu}>
    {#if dotsVariant === 'vertical'}
      <IconDotsThreeVertical weight='bold' />
    {:else}
      <IconDotsThree weight='bold' />
    {/if}
  </FakeButton>

  {#if showMenu}
    <FakeButton class={sideView === 'right' ? 'right-side' : 'left-side'} element='div' onClick={handleSlotClick}>
      <slot />
    </FakeButton>
  {/if}
</div>

<style lang='scss'>
  .kebab-wrapper {
    position: relative;
    display: inline-block;

    > :global(div) {
      position: absolute;
      right: 0;
      z-index: 20;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 17.8rem;
      background: var(--form-background);
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 8px;
      box-shadow: var(--Shadow-X);

      &:has(.section-wrapper:not(.-my)) {
        left: 0;
      }

      > :global(a), > :global(span), > :global(div) {
        display: flex;
        gap: 0.8rem;
        align-items: start;
        width: 100%;
        padding: 0.8rem 1.6rem;
        font: var(--Regular-400);
        color: var(--text-primary-color);

        &:hover {
          color: var(--text-tertiary-color);
        }
      }
    }

    > :global(.fake-button) {
      gap: 0;
    }

    > :global(.left-side .fake-button .reportFlag) {
      height: 2.4rem;
    }
  }
</style>
