<script lang='ts'>
  import { onMount } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import ScriptsTable from '@/components/advanced/ScriptsTable.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { userStorredConfig } from '@/store'

  export let iso = ''

  let scripts: Record<number, any[]> = {}
  let language = $userStorredConfig.id_lang_interface
  let title: string = ''
  let content: string = ''
  let showOverlay: boolean = false
  let scriptId = 0
  const loadScripts = async () => {
    const data = await fetchData('main/scriptsLoad', {})
    scripts = data.scripts || {}
  }

  onMount(() => {
    loadScripts()
  })

  const addScript = async () => {
    await fetchData('main/scriptsAdd', {
      content,
      id: scriptId,
      language,
      title
    })
    await loadScripts()
    showOverlay = false
  }

  const langChange = (lang: number) => {
    language = lang
    return true
  }

  const editScript = (script: any) => {
    content = script.content
    title = script.title
    language = script.id_language
    scriptId = script.id
    showOverlay = true
  }

  const deleteScript = async (script: any) => {
    if (confirm('Are you sure?')) {
      await fetchData('main/scriptsDelete', {
        id: script.id
      })
      await loadScripts()
    }
  }
</script>
<Breadcrumb
  breads={[
  {text:'',url:`/${iso}`},
  {text:'Language scripts'}
  ]}
/>
<h2>{$_('script.languageScripts')}</h2>
<p>{$_('script.learnWithScripts')}</p>


<p>{$_('script.availableScripts')}</p>
<ScriptsTable {deleteScript} {editScript} {iso} {scripts} />

<button type='button' on:click={() => {showOverlay=true}}>Add script</button>

{#if showOverlay}
  <Overlay onClick={() => {showOverlay=false}}>
    <h2>{$_('script.addScript')}</h2>
    <form class='form-content _form' action='' on:submit|preventDefault={addScript}>
      <LanguageSelect
        onChange={langChange}
        selectedLanguage={language}
      />
      <p>
        <label for='title'>{$_('mix.title')}</label>
        <input id='title' type='text' bind:value={title} />
      </p>
      <p>
        <label for='content'>{$_('mix.content')}</label>
        <textarea id='content' bind:value={content} />
      </p>
      <br />
      <p>
        <input type='submit' value='Add script' />
      </p>
    </form>
  </Overlay>
{/if}
