import { getIdFromIso } from '@/helpers/apiCall'

import { ChatMessage, UserLanguage } from '@/definitions/langoid'
import { MixedStoreData } from '@/definitions/stores'

export const tryToParse = (data: string, defaultValue: unknown = '') => {
  if (data === '' || data === null || data === undefined) return defaultValue
  try {
    return JSON.parse(data)
  } catch (e) {
    console.error('parsing error', data)
    return data
  }
}

export const normalizeMsg = (msg: ChatMessage): ChatMessage => {
  const {
    explanation,
    pos_tagged,
    posTagged
  } = msg
  const pt = pos_tagged || posTagged
  let posTagged2 = []
  try {
    posTagged2 = pt ? tryToParse(pt) : []
  } catch (e) {
    console.error('parsing error', pt, pos_tagged, posTagged)
  }
  const message = {
    ...msg,
    posTagged: posTagged2
  }

  if (explanation) {
    const {
      question,
      answer
    } = explanation
    message.explanation = {
      ...explanation,
      answer: typeof answer === 'string' ? tryToParse(answer) : answer,
      question: question
        ? question === 'yes' ? question : (typeof question === 'string' ? tryToParse(question) : question)
        : []
    }
  }

  return message
}

export function updateMessageCounts (m: MixedStoreData, selected: number, iso: string) {
  const langId = getIdFromIso(iso)
  if (m.messagesCounts?.[selected]?.[langId]) {
    delete m.messagesCounts[selected][langId]
  }
  return m
}

export const countChatMessages = (dataFromStore: MixedStoreData['messagesCounts']) => {
  return Object.values(dataFromStore || []).map(el => Object.values(el).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
}

export const sortLanguages = (languages: UserLanguage[], my: string[], current: string) => {
  return Object.values(languages || {}).sort((a, b) => {
    // Check if a language is the current language
    if (a.iso === current) { return -1 }
    if (b.iso === current) { return 1 }

    // Check if a language is in learning languages
    if (my.includes(a.iso) && !my.includes(b.iso)) { return -1 }
    if (!my.includes(a.iso) && my.includes(b.iso)) { return 1 }

    // Final condition: sorting by language id
    return a.id_language - b.id_language
  })
}
