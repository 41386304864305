<script lang='ts'>
  import { link } from 'svelte-routing'

  import { JWT_COOKIE_EXPIRATION_DAYS } from '@/config/constants'

  import { setCookie } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import IconCertificate from "@/components/icons/phosphor/IconCertificate.svelte";
  import IconSignOut from "@/components/icons/phosphor/IconSignOut.svelte";
  import IconUser from "@/components/icons/phosphor/IconUser.svelte";

  import { userStorredConfig } from '@/store'

  async function logOut () {
    const data = await fetchData('auth/logout', {})
    setCookie('jwt', '', JWT_COOKIE_EXPIRATION_DAYS)
    userStorredConfig.set({})
    location.href = data?.fb ? data.fb : '/'
  }
</script>

<ul class='profile-menu'>
  <li class='-userData'>{$userStorredConfig.name}</li>
  <li>
    <a class='link' href='/profile/{$userStorredConfig.id}' use:link>
      <IconUser weight='regular' />
      {$_('menu.myProfile')}</a></li>
  <li>
    <a class='link' href='/plans' use:link>
      <IconCertificate weight='regular' />{$_('menu.plans')}</a>
  </li>
  <li>
    <hr class='middleLine' />
  </li>
  <li>
    <a class='link' href='/logout' on:click|preventDefault={logOut}>
      <IconSignOut weight='regular' />{$_('menu.logout')}</a>
  </li>
</ul>

<style lang='scss'>
  .profile-menu {
    padding-left: 0;

    > li {
      position: relative;
      padding: 0;

      &.-userData {
        padding: 2.4rem 2rem;
        font-weight: 700;
        font-size: 1.8rem;
        color: var(--Base-White);
      }

      > .link {
        display: flex;
        gap: 2rem;
        align-items: center;
        padding: 1rem 1rem 1rem 2rem;
        font-size: 1.6rem;
        color: var(--Base-White);
        background-repeat: no-repeat;
        background-position: 2rem center;

        &:hover {
          background-color: var(--Primary-Dark);
        }
      }
    }

    > li > .middleLine {
      width: 85%;
      margin: 2rem auto 0.8rem;
    }
  }
</style>
