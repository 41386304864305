<!--
Variants descriptions:

* normal: same X on desktop and mobile, no border and no padding (usage: forum filters, share)
* mainManu: variant just for main menu, with absolute possition
* absolute: same X on desktop and mobile, no border and no padding, but with absolute possition (usage: user profile, share, comment)
* desktopBorder: X with border and padding for desktop - no border with padding for mobile
* overlay: same X on desktop and mobile, with border and padding
-->

<script lang='ts'>
  import IconX from "@/components/icons/phosphor/IconX.svelte";

  export let onClick = () => {
    },
    closeVariant: 'normal' | 'mainManu' | 'absolute' | 'overlay' | 'desktopBorder' = 'normal'
</script>
<div
  class='close-button'
  class:-absolute={closeVariant === 'absolute'}
  class:-mainManu={closeVariant === 'mainManu'}
  role='button'
  tabindex='0'
  on:keypress={() => {}}
  on:click={onClick}
>
  {#if closeVariant === 'overlay'}
    <span class='_icon'><IconX weight='regular' /></span>
  {:else if closeVariant === 'desktopBorder'}
    <span class='_icon -noBorder'><IconX weight='regular' /></span>
  {:else}
    <IconX weight='regular' />
  {/if}
</div>
<style lang='scss'>
  .close-button {
    cursor: pointer;

    &.-mainManu {
      position: absolute;
      top: 2.6rem;
      right: 1.6rem;
      z-index: 10;
      color: var(--Base-White);
    }

    &.-absolute {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      color: var(--text-primary-color);
    }
  }
</style>
