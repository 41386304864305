<script lang='ts'>
  import { links } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'

  import IconStar from "@/components/icons/phosphor/IconStar.svelte";
  import IconThumbsUp from "@/components/icons/phosphor/IconThumbsUp.svelte";
  import UserAvatar from '@/components/social/UserAvatar.svelte'

  import { mixStore } from '@/store'

  export let id: number,
    type = '',
    params: Record<string, string> = {},
    seen = 'no',
    date = '',
    creator: number,
    nick = '',
    name = '',
    avatar = '',
    points: number,
    level: number,
    onClick = () => { }

  const EXECUTION_CLICK_EVENT = 1000
  const user = {
    avatar,
    date,
    id: creator,
    level,
    name,
    nick,
    points
  }
  const correctionId = params.id_correction || params.correctionId || 0

  async function markAsRead (id: number) {
    if (seen === 'yes') {
      return
    }
    const { notificationsCount } = await fetchData('social/notificationsMarkAsRead', { id })
    mixStore.setKey('notificationsCount', notificationsCount)
    seen = 'yes'
  }
</script>

<div
  class='notification-row'
  class:-seen={seen === 'yes'}
  data-createdAt={date}
  role='button'
  tabindex='0'
  on:keypress={() => {}}
  on:click={() => {
    markAsRead(id)
    setTimeout(onClick, EXECUTION_CLICK_EVENT)
  }}
  use:links
>
  <div class='avatar-and-link'>
    <div class='avatar-wrapper'>
      <UserAvatar avatarVariant='smallAvatar' dateVariant='withDate' metaDataVariant='right' {user} />
    </div>
    <div class='link-and-date'>
      <div class='notification-link' class:-seen={seen === 'yes'}>
        {#if type === 'friend_request'}
          <span class='bulb'><a href='/profile/{params?.id || 0}'>{$_('notifications.friendRequest')}&nbsp;<b>{params?.nick || params?.name}</b></a></span>
        {:else if type === 'friend_accept'}
          <span class='bulb'><a href='/profile/{params?.id || 0}'>{$_('notifications.friendAccepted')}&nbsp;<b>{params?.nick || params?.name}</b></a></span>
        {:else if type === 'comment'}
          {#if toInt(correctionId) > 0}
            <a class='newVote' href='{params.link}?comment={params.id}'>{$_('notifications.newCommentOnYourCorrection')}</a>
          {:else}
            <a class='newVote' href='{params.link}?comment={params.id}'>{$_('notifications.newCommentOnYourTask')}</a>
          {/if}
        {:else if type === 'comment_vote'}
          <a class='newVote' href='{params.link}?comment={params.id}'>{$_('notifications.newVoteOnYourComment')}
            <IconThumbsUp size='18' />
          </a>
        {:else if type === 'task_vote'}
          <a class='newVote' href={params.link}>{$_('notifications.newVoteOnYourTask')}
            <IconThumbsUp size='18' />
          </a>
        {:else if type === 'correction'}
          <a class='newVote' href='{params.link}?correction={correctionId}'>{$_('notifications.newCorrectionOnYourTask')}</a>
        {:else if type === 'correction_vote'}
          <a class='newVote' href='{params.link}?correction={correctionId}'>{$_('notifications.newVoteOnYourCorrection')}
            <IconThumbsUp size='18' />
          </a>
        {:else if type === 'correction_perfect'}
          <a class='bulb' href='{params.link}?correction={correctionId}'>{$_('notifications.correctionMarkedAsPerfect')}</a>
        {:else if type === 'task_perfect'}
          <a class='bulb' href={params.link}>{$_('notifications.taskMarkedAsPerfect')}</a>
        {:else if type === 'badge'}
          <a class='bulb' href={params.link}>{$_('notifications.badge', { values: { badge: params.template } })}</a>
        {:else if type === 'level'}
          <span class='bulb'>{$_('notifications.level', { values: { level: params?.now || '' } })}
            <IconStar size='18' /></span>
        {:else if type === 'forum_reply'}
          <a class='bulb' href={params.link}>{$_('notifications.forumReply')}</a>
        {:else if type === 'goal_failed'}
          <a class='bulb' href={params.link}>{$_('notifications.goalFailed')}</a>
        {:else if type === 'goal_will_fail'}
          <a class='bulb' href={params.link}>{$_('notifications.goalWillFail')}</a>
        {:else}
          {id} {type}
        {/if}
      </div>
      <p class='createAt'>{date}</p>
    </div>
  </div>
  <button class='stayOpen' type='button' />
</div>

<style lang='scss'>
  .notification-row {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem;
    border-top: 0.1rem solid var(--text-background);

    > .stayOpen {
      width: 1.6rem;
      min-width: 1.2rem;
      height: 1.6rem;
      padding: 0;
      background-color: var(--Primary-Medium);
      border: 0.2rem solid var(--main-background);
      border-radius: 50%;
    }

    &.-seen {
      font-weight: normal;
      background: transparent;

      > .stayOpen {
        visibility: hidden;
      }
    }

    &:hover {
      background-color: var(--hover-background);
    }

    &:last-child {
      border-bottom: 0.1rem solid var(--text-background);
    }
  }

  .avatar-and-link {
    display: flex;
    align-items: center;
  }

  .avatar-wrapper {
    min-width: 9.6rem;
  }

  .link-and-date {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    > .createAt {
      font: var(--Regular-200);
    }
  }

  .notification-link {
    > .newVote, > .bulb {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      font-weight: bolder;
    }

    > .bulb {
      cursor: pointer;
    }

    &.-seen {
      > .newVote, > .bulb {
        font-weight: normal;
      }
    }
  }

  @media (max-width: 768px) {
    .notification-row {
      padding-left: 0;
      font-size: 1.2rem;
    }
  }
</style>
