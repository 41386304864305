<!--
closeLeft:
* false: default variant with GoBack left arrow for desktop and mobile
* true: used on pages with one URL but with 2 different views (like on listen page and test page), where close button is on the left side of Breadrumb, insted left arrow icon. Is combined with "secondPageView" props.
-->
<script lang='ts'>
  import { createEventDispatcher, onDestroy } from 'svelte'
  import { link, useLocation } from 'svelte-routing'

  import { clickOutside } from '@/helpers/mixHelpers'

  import IconArrowLeft from "@/components/icons/phosphor/IconArrowLeft.svelte";
  import IconDotsThreeVertical from "@/components/icons/phosphor/IconDotsThreeVertical.svelte";
  import IconHouse from "@/components/icons/phosphor/IconHouse.svelte";
  import BreadLinks from '@/components/ui/BreadLinks.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { Bread } from '@/definitions/langoid'

  export const iso = ''
  export let closeLeft = false,
    filters = false,
    secondPageView = false,
    breads: Bread[] = []

  // todo: window.history.length > 1 - if this is greater than 1 that means browser back button can be activated

  let menuGroups: string[] = []
  let currentLocation: string[] = []
  let showHelperIcons = false
  const locationStore = useLocation()

  let prevPage = breads[0]?.url || ''
  const unsubscribe = locationStore.subscribe(routerData => {
    currentLocation = routerData.pathname.split('/')
    menuGroups = routerData.pathname.split('/')
    if (!prevPage) prevPage = menuGroups.splice(0, menuGroups.length - 1).join('/')
  })

  onDestroy(() => {
    unsubscribe()
  })

  const dispatch = createEventDispatcher()
  const toggleHelperIcons = () => {
    showHelperIcons = !showHelperIcons
  }

  /*  const closeHelperIcons = () => {
      showHelperIcons = false
    }*/

  const closeSecondPageView = () => {
    secondPageView = !secondPageView
    dispatch('closePageView', secondPageView)
  }

</script>
<div class='breadcrumb-area'>
  <div class='left-side'>
    <div class='bread-crumb'>
      {#if !closeLeft}
        {#if breads[0]?.mobileUrl}
          <a class='_icon -bigNoBorder _mobileOnly' href={breads[0]?.mobileUrl} use:link>
            <IconArrowLeft weight='regular' />
          </a>
          <a class='_icon -bigNoBorder _desktopOnly' href={breads[0]?.url} use:link>
            <IconHouse weight='regular' />
          </a>
        {:else}
          <a class='_icon -bigNoBorder' href={prevPage} use:link>
            <span class='_desktopOnly'><IconHouse weight='regular' /></span>
            <span class='_mobileOnly'><IconArrowLeft weight='regular' /></span>
          </a>
        {/if}
      {:else}
        <div class='go-back-mob'>
          <CloseButton closeVariant='desktopBorder' onClick={closeSecondPageView} />
        </div>
      {/if}
      <slot name='avatar' />
      <div class='backLinks' class:-hide={closeLeft}>
        <BreadLinks {breads} {currentLocation} />
      </div>
    </div>
    <!--For headind that is visible for mobile in Breadcrumb area, and hidden from desktop main content-->
    <div class='_mobileOnly'>
      <slot />
    </div>
    <div class='tabs-slot'>
      <slot name='tabs' />
    </div>
  </div>
  <!--For progress bar that is visible for mobile in Breadcrumb area, and hidden from desktop main content-->
  <div class='progress-bar-slot'>
    <slot name='progressBar' />
  </div>
  <div class='right-side'>
    <div class='_mobileOnly'>
      <slot name='filters' />
    </div>
    <div class='helper-icons-wrapper' class:-filters={filters}>
      <div class='kebab-icon'>
        <FakeButton class='_icon -noBorder' onClick={toggleHelperIcons}>
          <IconDotsThreeVertical weight='bold' />
        </FakeButton>
      </div>
      <div
        class='helper-icons-kebab'
        class:-show={showHelperIcons}
        role='button'
        tabindex='0'
        on:click={() => {showHelperIcons = false}}
        on:keypress={() => {}}
        on:click_outside={() => {showHelperIcons = false}}
        use:clickOutside
      >
        <slot name='pageHelperIcons' />
      </div>
    </div>
  </div>
</div>

<style lang='scss'>
  .breadcrumb-area {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left-side {
    display: flex;
    align-items: center;
  }

  .bread-crumb {
    display: flex;
    gap: 1.6rem;
    align-items: center;

    > .backLinks {
      white-space: nowrap;
    }
  }

  .tabs-slot {
    display: block;
  }

  .progress-bar-slot {
    width: 100%;

    &:empty {
      display: none;
    }
  }

  .right-side {
    display: flex;
    gap: 0.4rem;
  }

  .helper-icons-wrapper {
    display: flex;
    gap: 0.4rem;
    min-width: 4.4rem;

    &.-filters {
      display: none;
    }
  }

  .kebab-icon {
    display: none;
  }

  @media (max-width: 768px) {
    .breadcrumb-area {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 6.4rem;
      padding: 0.4rem;
      background-color: var(--main-background);
      box-shadow: 0 0.1rem 0.3rem 0 var(--Gray-Light);
    }
    .bread-crumb {
      gap: 0;

      > .backLinks {
        display: none;
      }
    }

    .tabs-slot {
      display: none;
    }

    .kebab-icon {
      display: block;
    }

    .helper-icons-kebab {
      display: none;

      &.-show {
        display: flex;

        > :global(div) {
          position: absolute;
          top: 5rem;
          right: 1.2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-width: 18.8rem;
          background: var(--main-background);
          border: 0.1rem solid var(--Gray-Light);
          border-radius: 8px;
          box-shadow: var(--Shadow-X);
        }

        > :global(div span):hover {
          background-color: unset;
          border: none;
        }
      }
    }
  }
</style>
