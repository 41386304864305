<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import IconFlag from "@/components/icons/phosphor/IconFlag.svelte";
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import Overlay from './Overlay.svelte'

  export let rel: string | number = '',
    category = '',
    selected = false,
    kebabItem = false // If we want to have Report icon with text (example: for mobile view inside kebab select list) we have to pass this paramether from parent component

  const dispatch = createEventDispatcher()
  const TIME_TO_RESET_VARIABLES_ON_DEFAULT = 1000
  const TIME_TO_RESET_ALERT_MESSAGE = 3000

  let showReportModal = false,
    type = 'dontLike',
    description = '',
    errorMessage: string,
    successMessage: string

  const handleOnSubmit = async () => {
    const params = {
      category,
      description,
      rel,
      type
    }
    await fetchData('main/report', params)
    dispatch('report', params)
    selected = true
    successMessage = $_('report.sent')
    setTimeout(() => {
      showReportModal = false
      type = 'dontLike'
      description = ''
    }, TIME_TO_RESET_VARIABLES_ON_DEFAULT)
    setTimeout(() => {
      successMessage = ''
      errorMessage = ''
    }, TIME_TO_RESET_ALERT_MESSAGE)
  }

  const openModal = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()// when called from forum pages will do redirect without this
    showReportModal = true
  }
  const closeModal = () => {
    showReportModal = false
  }
</script>
<FakeButton onClick={openModal}>
  {#if category === 'forumTopic' || category === 'forumReply'}
    <span class='reportFlag' title={$_('report.report')}>
      <IconFlag weight={selected? 'fill' : 'regular'} />
    </span>
    <span>{$_('report.report')}</span>
  {:else if category === 'comment'}
    <span class='reportFlag -noBorder _icon' title={$_('report.report')}>
      <IconFlag size='20' weight={selected ? 'fill' : 'regular'} />
    </span>
  {:else}
    <span class='reportFlag -noBorder _icon' title={$_('report.report')}>
      <IconFlag weight={selected ? 'fill' : 'regular'} />
    </span>
    {#if kebabItem}
      <span class='reportLabel'>{$_('report.report')}</span>
    {/if}
  {/if}
</FakeButton>

{#if showReportModal}
  <Overlay absoluteCloseButton onClick={closeModal}>
    <h2>{$_('report.reason')}</h2>
    <form
      class='report-form'
      role='presentation'
      on:submit|preventDefault={handleOnSubmit}
      on:click|stopPropagation
      on:keypress|stopPropagation
    >
      {#if category === 'user'}
        <label><input type='radio' value='adult' bind:group={type} />
          {$_('report.adult')}</label>
        <label><input type='radio' value='spam' bind:group={type} />
          {$_('report.spam')}</label>
        <label><input type='radio' value='ad' bind:group={type} />
          {$_('report.ad')}</label>
      {:else if category === 'lesson_sentence' || category === 'we_word'}
        <label><input type='radio' value='bad_audio' bind:group={type} />
          {$_('testsPage.badAudio')}</label>
        <label><input type='radio' value='error_word' bind:group={type} />
          {$_('report.wordHasError')}</label>
        {#if category === 'lesson_sentence'}
          <label><input type='radio' value='error_sentence' bind:group={type} />
            {$_('report.sentenceHasError')}</label>
        {/if}
      {:else if category === 'listen'}
        <label><input type='radio' value='bad_audio' bind:group={type} />
          {$_('testsPage.badAudio')}</label>
        <label><input type='radio' value='error_sentence' bind:group={type} />
          {$_('report.sentenceHasError')}</label>
      {:else if category === 'test_question'}
        <label><input type='radio' value='bad_audio' bind:group={type} />
          {$_('testsPage.badAudio')}</label>
        <label><input type='radio' value='unclear' bind:group={type} />
          {$_('testsPage.notClear')}</label>
        <label><input type='radio' value='error' bind:group={type} />
          {$_('testsPage.questionHaveError')}</label>
      {:else if category === 'test_sentence'}
        <label><input type='radio' value='error' bind:group={type} />
          {$_('testsPage.questionHaveError')}</label>
        <label><input type='radio' value='unclear' bind:group={type} />
          {$_('testsPage.notClear')}</label>
      {:else if category === 'general'}
        <label><input type='radio' value='wrong_info' bind:group={type} />
          {$_('report.wrongInfo')}</label>
        <label><input type='radio' value='bug' bind:group={type} />
          {$_('report.bug')}</label>
        <label><input type='radio' value='general' bind:group={type} />
          {$_('report.uncategorized')}</label>
      {:else if category === 'mission'}
        <label><input type='radio' value='wrong_info' bind:group={type} />
          {$_('report.wrongInfo')}</label>
        <label><input type='radio' value='bug' bind:group={type} />
          {$_('report.bug')}</label>
        <label><input type='radio' value='general' bind:group={type} />
          {$_('report.uncategorized')}</label>
      {:else if category === 'task'}
        <label><input type='radio' value='rude' bind:group={type} />
          {$_('report.rude')}</label>
        <label><input type='radio' value='general' bind:group={type} />
          {$_('report.uncategorized')}</label>
      {:else if category === 'correction'}
        <label><input type='radio' value='rude' bind:group={type} />
          {$_('report.rude')}</label>
        <label><input type='radio' value='general' bind:group={type} />
          {$_('report.uncategorized')}</label>
      {:else if category === 'comment'}
        <label><input type='radio' value='rude' bind:group={type} />
          {$_('report.rude')}</label>
        <label><input type='radio' value='general' bind:group={type} />
          {$_('report.uncategorized')}</label>
      {:else if category === 'forumTopic' || category === 'forumReply'}
        <h2>{$_('report.downvote')}</h2>
        <label><input type='radio' value='dontLike' bind:group={type} />
          {$_('report.dontLike')}</label>
        <label><input type='radio' value='hasFewErrors' bind:group={type} />
          {$_('report.hasFewErrors')}</label>
        <label><input type='radio' value='hasSoManyErrors' bind:group={type} />
          {$_('report.hasSoManyErrors')}</label>
        <label><input type='radio' value='lowQuality' bind:group={type} />
          {$_('report.lowQuality')}</label>
        <label><input type='radio' value='iWishToSeeLessOfTHis' bind:group={type} />
          {$_('report.iWishToSeeLessOfTHis')}</label>
        {#if category === 'forumTopic'}
          <label><input type='radio' value='wrongCategory' bind:group={type} />
            {$_('report.uncategorized')}</label>
        {/if}
        <h2>{$_('report.deleted')}</h2>
        <label><input type='radio' value='rude' bind:group={type} />
          {$_('report.rude')}</label>
        <label><input type='radio' value='adult' bind:group={type} />
          {$_('report.adult')}</label>
        <label><input type='radio' value='spam' bind:group={type} />
          {$_('report.spam')}</label>
        <label><input type='radio' value='ad' bind:group={type} />
          {$_('report.ad')}</label>
      {/if}
      <label><input type='radio' value='other' bind:group={type} />
        {$_('mix.other')}</label>
      {#if type === 'other'}
        <label>
          {$_('report.description')}
          <textarea cols='30' required rows='5' bind:value={description} />
        </label>
      {/if}
      {#if errorMessage}
        <p class='errorMessage'>{errorMessage}</p>
      {/if}
      {#if successMessage}
        <p class='successMessage'>{successMessage}</p>
      {/if}
      <input class='report-button' type='submit' value={$_('report.send')} />
    </form>
  </Overlay>
{/if}

<style lang='scss'>
  :global(.fake-button .reportFlag) {
    color: var(--Error-Medium);
  }

  /*  :global(.fake-button .reportFlag.-noBorder) {
      width: fit-content;
      height: fit-content;
      background-color: inherit;
      border: none;
    }*/

  :global(.fake-button .reportLabel) {
    display: none;
    color: var(--Error-Medium);
  }

  .report-form {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    min-width: 23rem;
    font: var(--Regular-400);

    > .errorMessage {
      font-weight: bold;
      font-size: 1.4rem;
      color: var(--color-error);
    }

    > .successMessage {
      font-weight: bold;
      font-size: 2.4rem;
      color: var(--color-success);
    }
  }

  @media (max-width: 768px) {
    :global(.fake-button .reportLabel) {
      display: flex;
      align-self: center;
    }

    :global(.fake-button:has(.reportLabel)) {
      gap: 0;
      width: 100%;
    }

    label {
      font-size: 1.8rem;
    }
  }
</style>
