import { get } from 'svelte/store'

import { ISO_LENGTH, MS_PER_DAY } from '@/config/constants'
import { languages } from '@/config/languages'

import { _ } from '@/helpers/i18n'

import { formatActivities } from './formatActivities'

import { userStorredConfig } from '@/store'

export const STAGE = import.meta.env?.VITE_PUBLIC_STAGE || 'TEST'

let CONFIG = {
  API_PUBLIC_URL: 'https://api.langoid.com',
  CHAT_URL: 'wss://chat.langoid.com/',
  FULL_HOST: 'https://langoid.com',
  HOST: 'langoid.com'
}

if (STAGE === 'TEST') {
  CONFIG = {
    API_PUBLIC_URL: 'https://api.brankus.com',
    CHAT_URL: 'wss://chat.brankus.com/',
    FULL_HOST: 'https://demo.brankus.com',
    HOST: 'brankus.com'
  }
} else if (STAGE === 'LOCAL') {
  CONFIG = {
    API_PUBLIC_URL: 'https://api.lang.com',
    CHAT_URL: 'ws://localhost:9090/',
    FULL_HOST: 'https://lang.com:5555',
    HOST: 'lang.com'
  }
} // else STAGE === 'LIVE' use default config

export const API_PUBLIC_URL = CONFIG.API_PUBLIC_URL
export const API_URL = `${API_PUBLIC_URL}/v1`
export const CHAT_URL = CONFIG.CHAT_URL

export const getLearningLanguageId = (): number => getIdFromIso(getIsoFromUrl())

export const getIsoFromUrl = () => {
  const tmp = location.pathname.split('/')[1]
  return tmp.length === ISO_LENGTH ? tmp : getIso()
}

// todo - all this with default language probably should not used default language
export const getIso = (): string => {
  const config = get(userStorredConfig)
  if (config.id_lang_learning) return getIsoFromId(config.id_lang_learning) || 'eng'
  return 'eng'
}

export function getIdFromIso (iso: string): number {
  return languages?.find(item => item.iso === iso)?.id || 0
}

export function getIsoFromId (id: number | string, strict = false): string {
  if (typeof id === 'string') id = parseInt(id, 10)
  const iso = languages?.find(item => item.id === id)?.iso
  return iso || (strict ? '' : 'eng')
}

export const getInterfaceLanguageId = () => {
  const { id_lang_interface: langInterface } = get(userStorredConfig)
  if (langInterface) {
    return langInterface
  }
  // eslint-disable-next-line no-console
  console.log('Default values pulled. Why?')
  return getIdFromIso(getInterfaceIso())
}

export const getInterfaceIso = () => {
  const config = get(userStorredConfig)
  const langInterface: number = config.id_lang_interface
  if (langInterface) return getIsoFromId(langInterface)
  return 'eng'
}

export const apiCall = async (url: string, data = {}, method = 'POST') => {
  const languageId = getLearningLanguageId()
  const interfaceId = getInterfaceLanguageId()
  if (interfaceId === languageId) {
    if ([
      'test/qualificationTestPage',
      'test/qualificationTestCheck',
      'test/qualificationTestStart'
    ].includes(url)) { // todo: List all urls where we want to prevent same learning and interface language
      const error = get(_)('error.chooseDifferentLanguage')
      return {
        error,
        json: () => ({ error })
      }
    }
  }
  const activityLog = formatActivities(localStorage.getItem('activity') || '[]')
  localStorage.setItem('activity', '[]')
  const firstSegment = location.pathname.split('/')[1] || ''
  const jwt = getCookie('jwt')
  if (jwt) {
    return await fetch(`${API_URL}/${url}`, {
      body: JSON.stringify({
        activityLog,
        firstSegment,
        interfaceId,
        languageId, ...data
      }),
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + jwt,
        // 'X-FP-API-KEY': 'iphone', //it can be iPhone or your any other attribute
        'Content-Type': 'application/json'
      },
      method
    })
  }

  return await fetch(`${API_URL}/${url}`, {
    body: JSON.stringify({
      anon: true,
      firstSegment,
      interfaceId,
      languageId, ...data
    }),
    headers: {
      'Content-Type': 'application/json'
    },
    method
  })
}

export function setCookie (cname: string, cvalue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * MS_PER_DAY)
  const expires = 'expires=' + d.toUTCString()
  let domainPart
  if (location.host.includes('langoid.com')) {
    domainPart = 'domain=langoid.com;'
  } else {
    if (location.host.includes('lang.com')) {
      domainPart = 'domain=lang.com;'
    } else {
      domainPart = ''
    }
  }
  document.cookie = `${cname}=${cvalue};${expires};${domainPart}path=/;secure`
}

// get or read cookie
export function getCookie (cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
