<script>
  import { link } from 'svelte-routing'

  import { _ } from '@/helpers/i18n'

  import IconCrown from '@/components/icons/phosphor/IconCrown.svelte'
  import IconIdentificationCard from '@/components/icons/phosphor/IconIdentificationCard.svelte'
  import IconMagnifyingGlass from '@/components/icons/phosphor/IconMagnifyingGlass.svelte'
  import IconUserCircleGear from '@/components/icons/phosphor/IconUserCircleGear.svelte'
  import IconUsersThree from '@/components/icons/phosphor/IconUsersThree.svelte'
  import LangoidSocialLinks from '@/components/LangoidSocialLinks.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'

  import { userStorredConfig } from '@/store'

  const user = $userStorredConfig

</script>
<Breadcrumb>
  <h2 class='heading-mobile'>Social</h2>
</Breadcrumb>
<div class='social-page _gap24'>
  <a class='link' href='/forum' use:link>
    <IconUsersThree size='64' />
    <p>{$_('menu.forum')}</p>
  </a>
  <a class='link' href='/search-users' use:link>
    <IconMagnifyingGlass size='64' />
    <p>{$_('menu.searchUsers')}</p>
  </a>
  <a class='link' href='/rang-list' use:link>
    <IconUserCircleGear size='64' />
    <p>{$_('menu.statsAll')}</p>
  </a>
  <a class='link -admin' href='/admin-messages' use:link>
    <IconIdentificationCard size='64' />
    <p>{$_('menu.admin')}</p>
  </a>
  {#if user.is_admin}
    <a class='link -moderator' href='https://mod.langoid.com'>
      <IconCrown size='64' />
      <p>{$_('menu.moderator')}</p>
    </a>
  {/if}
  <div class='social-links'>
    <LangoidSocialLinks />
  </div>
</div>

<style lang='scss'>
  .social-page {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-columns: repeat(3, 15rem);
    justify-content: space-around;
    max-width: 68.4rem;
    margin: auto;

    > .link {
      width: 100%;
      height: 100%;
      padding: 2.4rem;
      text-align: center;
      background-repeat: no-repeat;
      border: 0.1rem solid var(--Gray-Lighter);
      border-radius: 1.2rem;
      box-shadow: var(--light-box-shadow);

      &:hover {
        background: var(--Gray-Lighter);
        border: 0.1rem solid var(--Primary-Lighter);
        cursor: pointer;
      }
    }
  }

  .social-links {
    display: grid;
    grid-gap: 1.2rem;
    grid-template-columns: repeat(3, 1fr);
    max-width: 51.2rem;
    margin: auto;
  }

  @media (max-width: 768px) {
    .social-page {
      grid-template-columns: repeat(2, 1fr);
      max-width: 100%;
    }
  }
</style>
