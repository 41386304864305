import { get, set } from 'idb-keyval'

import { logger } from '@/helpers/logger'

import { fetchData } from './fetchHelpers'

import { Vocabulary } from '@/definitions/langoid'

export const initializeAndUpdateDb = async () => {
  const data = await get('my-vocabulary')
  fetchData('chat/vocabulary', { userId: 0 }).then((res) => {
    updateMyVocabulary(res)
  })
  return data
}

export const updateMyVocabulary = async (data: any) => {
  await set('my-vocabulary', data)
}

export const updateFriendVocabulary = async (friendId: number) => {
  if (!friendId) return
  const data = await fetchData('chat/vocabulary', { userId: friendId })
  if (data?.length > 0) {
    await set('friend-vocabulary-' + friendId, data)
  }
}

export const getWordsInfo = async (ids: number[], readFrom = 'vocabulary', userId: number) => {
  try {
    if (readFrom === 'vocabulary') {
      const data = await get('my-vocabulary') || []
      return data.filter((item: Vocabulary) => ids.includes(item.id_word))
    } else {
      const data = await get('friend-vocabulary-' + userId)
      return data.filter((item: Vocabulary) => ids.includes(item.id_word))
    }
  } catch (e) {
    logger.error(e)
  }
}

// showEstimatedQuota() // Check available space on disk
export async function showEstimatedQuota () {
  if (navigator.storage && navigator.storage.estimate) {
    const estimation = await navigator.storage.estimate()
    // eslint-disable-next-line no-console
    console.log(`Quota: ${estimation.quota} \n Usage: ${estimation.usage}`)
  } else {
    logger.error('StorageManager not found')
  }
}
