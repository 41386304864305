<script>
  import IconDiscordLogo from '@/components/icons/phosphor/IconDiscordLogo.svelte'
  import IconFacebookLogo from '@/components/icons/phosphor/IconFacebookLogo.svelte'
  import IconInstagramLogo from '@/components/icons/phosphor/IconInstagramLogo.svelte'
  import IconLinkedinLogo from '@/components/icons/phosphor/IconLinkedinLogo.svelte'
  import IconTwitterLogo from '@/components/icons/phosphor/IconTwitterLogo.svelte'
  import IconYoutubeLogo from '@/components/icons/phosphor/IconYoutubeLogo.svelte'
</script>

<a href='https://www.youtube.com/@langoidapp' rel='noopener noreferrer' target='_blank'>
  <IconYoutubeLogo />
</a>
<a href='https://www.facebook.com/langoidApp' rel='noopener noreferrer' target='_blank'>
  <IconFacebookLogo />
</a>
<a href='https://www.instagram.com/langoidapp/' rel='noopener noreferrer' target='_blank'>
  <IconInstagramLogo />
</a>
<a href='https://twitter.com/LangoidApp' rel='noopener noreferrer' target='_blank'>
  <IconTwitterLogo />
</a>
<a href='https://www.linkedin.com/company/tutor-d-o-o' rel='noopener noreferrer' target='_blank'>
  <IconLinkedinLogo />
</a>
<a href='https://discord.gg/WTpx3fvWDs' rel='noopener noreferrer' target='_blank'>
  <IconDiscordLogo />
</a>
<style lang='scss'>
  a {
    display: inline-block;
    margin: 0 1rem 0 0;
  }
</style>
